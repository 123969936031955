import { YOU_TURN_LOGO_BLUE } from "@src/helpers/image_constants";
import React from "react";

const Loading = () => {
  return (
    <div id="preloader">
      <div id="loader" class="loader">
        <div class="loader-container">
          <div class="loader-icon">
            <img src={YOU_TURN_LOGO_BLUE} alt="Preloader" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
