// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2000px) and (max-width: 2559px) {
  .featured_story_video_title {
    color: #ffffff;
    font-size: 34px;
  }
  .featured_story_video {
    font-size: 44px;
  }
  .feature_video_text {
    font-size: 30px !important;
  }
  .featured_video_thumbnail_overlay_content {
    width: 72% !important;
  }
  .featured_article_image_component {
    width: 60% !important;
  position: relative;

  }
  .featured_videos_thumbnail_overlay_play_icon {
    width: 135px;
  }
  .viewed_count_in_videos {
    font-size: 23px;
  }
  .featured_story_video_container_mobile_view {
    display: none !important;
  }
  .video_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
  }
  .video_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  .video_title {
    font-size: 26px;
  }
  .video_thumbnail {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 10px 10px 0 0;
  }
  .see_more_videos {
    font-size: 24px;
    height: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_2000_2559/screen_2000_2559_videos.css"],"names":[],"mappings":"AAAA;EACE;IACE,cAAc;IACd,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB,kBAAkB;;EAElB;EACA;IACE,YAAY;EACd;EACA;IACE,eAAe;EACjB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,oDAAoD;EACtD;EACA;IACE,aAAa;IACb,qCAAqC;IACrC,SAAS;EACX;EACA;IACE,eAAe;EACjB;EACA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,4BAA4B;EAC9B;EACA;IACE,eAAe;IACf,YAAY;EACd;AACF","sourcesContent":["@media only screen and (min-width: 2000px) and (max-width: 2559px) {\r\n  .featured_story_video_title {\r\n    color: #ffffff;\r\n    font-size: 34px;\r\n  }\r\n  .featured_story_video {\r\n    font-size: 44px;\r\n  }\r\n  .feature_video_text {\r\n    font-size: 30px !important;\r\n  }\r\n  .featured_video_thumbnail_overlay_content {\r\n    width: 72% !important;\r\n  }\r\n  .featured_article_image_component {\r\n    width: 60% !important;\r\n  position: relative;\r\n\r\n  }\r\n  .featured_videos_thumbnail_overlay_play_icon {\r\n    width: 135px;\r\n  }\r\n  .viewed_count_in_videos {\r\n    font-size: 23px;\r\n  }\r\n  .featured_story_video_container_mobile_view {\r\n    display: none !important;\r\n  }\r\n  .video_card {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    width: 100%;\r\n    cursor: pointer;\r\n    border-radius: 10px;\r\n    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);\r\n  }\r\n  .video_list {\r\n    display: grid;\r\n    grid-template-columns: repeat(3, 1fr);\r\n    gap: 30px;\r\n  }\r\n  .video_title {\r\n    font-size: 26px;\r\n  }\r\n  .video_thumbnail {\r\n    width: 100%;\r\n    height: auto;\r\n    cursor: pointer;\r\n    border-radius: 10px 10px 0 0;\r\n  }\r\n  .see_more_videos {\r\n    font-size: 24px;\r\n    height: 50px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
