import CryptoJS from "crypto-js";
import moment from "moment";
import { retrieve_item } from "./cryptos";
import {
  EMAIL_LOGO,
  FACEBOOK_FILED_LOGO,
  LINKEDIN_FILED_LOGO,
  LINK_ICON,
  TELEGRAM_FILED_LOGO,
  TWITTER_FILED_LOGO,
  WHATSAPP_FILED_LOGO,
} from "./image_constants";
import { Tag, Tooltip } from "antd";
import { changeLanguage } from "i18next";
import { language } from "./constants";
import { useReadingTime } from "react-hook-reading-time";

export function store_item(key, data) {
  const salt = process.env.REACT_APP_SALT || "";
  let value = JSON.stringify(data);
  let encryptedKey = window.btoa(key);
  let encryptedValue = encrypt_data(value, salt);
  window.localStorage.setItem(encryptedKey, encryptedValue);
  window.dispatchEvent(new Event(`${key}`));
}
export function format_view_count(viewCount) {
  if (viewCount || viewCount === 0) {
    let formattedNumber;

    if (viewCount >= 1000) {
      const countInK = Math.floor(viewCount / 1000);
      formattedNumber = countInK + "k";
    } else {
      formattedNumber = viewCount.toLocaleString("en-US", {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber.replace(/,/g, ", ");
  } else {
    return "0";
  }
}

export function clear_local_storage() {
  try {
    window.localStorage.clear();
  } catch (e) {
    return;
  }
}

export const encrypt_data = (data, salt) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decrypt_data = (cipher_text, salt) => {
  const bytes = CryptoJS.AES.decrypt(cipher_text, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export const split_tamil_english_words = (text, from, to) => {
  const mixedWordsArray = text?.match(/[\u0B80-\u0BFF\w.]+|\d+(?:\.\d+)?/g);
  let dots = mixedWordsArray?.length > 10 ? "..." : "";
  let result = mixedWordsArray
    ? mixedWordsArray.slice(from ?? 0, to ?? 10).join(" ")
    : [];

  return `${result}${dots}`;
};

export const format_views_count = (views_count) => {
  if (views_count >= 1000000) {
    return (views_count / 1000000).toFixed(1).replace(".0", "") + "M";
  } else if (views_count >= 1000) {
    return (views_count / 1000).toFixed(1).replace(".0", "") + "k";
  } else {
    return views_count?.toString();
  }
};

export const split_title = (text, tamil = 70, english = 40) => {
  const get_language = retrieve_item("language");
  let to = tamil || english;
  // if (get_language?.name === "tamil") {
  //   to = 70;
  // }
  const dots = text?.length > 10 ? "..." : "";

  const result = text ? text?.slice(0, to) : "";

  return `${result}${dots}`;
};
let store_order_id = "";

export const split_content = (htmlContent, size) => {
  // const { size } = useDynamicSelector("screen_width");
  const get_language = retrieve_item("language");
  const charSize = get_language?.name === "tamil" ? 3 : 4;
  const maxChars = Math.floor(1500 / charSize);

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Get text content without HTML tags
  let textContent = doc.body.textContent || "";
  const urlRegex = /https?:\/\/[^\s]+/g;
  textContent = textContent.replace(urlRegex, "").trim();

  // Split the text into words
  const mixedWordsArray =
    textContent.match(/[\u0B80-\u0BFF\w.]+|\d+(?:\.\d+)?/g) || [];

  let truncatedText = "";
  let charCount = 0;

  for (let word of mixedWordsArray) {
    charCount += word.length + 1; // +1 for the space
    if (charCount > maxChars) break;
    truncatedText += word + " ";
  }

  truncatedText = truncatedText.trim();
  if (charCount > maxChars) truncatedText += "...";
  return truncatedText;
};
export const size_wise_split_content = (
  htmlContent,
  size,
  tamil = 5,
  english = 4
) => {
  // const { size } = useDynamicSelector("screen_width");
  const get_language = retrieve_item("language");
  const charSize = get_language?.name === "tamil" ? tamil : english;
  const maxChars = Math.floor(size / charSize);

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Get text content without HTML tags
  let textContent = doc.body.textContent || "";
  const urlRegex = /https?:\/\/[^\s]+/g;
  textContent = textContent.replace(urlRegex, "").trim();

  // Split the text into words
  const mixedWordsArray =
    textContent.match(/[\u0B80-\u0BFF\w.]+|\d+(?:\.\d+)?/g) || [];

  let truncatedText = "";
  let charCount = 0;

  for (let word of mixedWordsArray) {
    charCount += word.length + 1; // +1 for the space
    if (charCount > maxChars) break;
    truncatedText += word + " ";
  }

  truncatedText = truncatedText.trim();
  if (charCount > maxChars) truncatedText += "...";
  return truncatedText;
};

export const size_wise_split_title = (htmlContent, size) => {
  const get_language = retrieve_item("language");
  const charSize = get_language?.name === "tamil" ? 20 : 4;
  const maxChars = Math.floor(size / charSize);

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Get text content without HTML tags
  let textContent = doc.body.textContent || "";
  const urlRegex = /https?:\/\/[^\s]+/g;
  textContent = htmlContent;

  // Split the text into words
  const mixedWordsArray =
    textContent.match(/[\u0B80-\u0BFF\w.]+|\d+(?:\.\d+)?/g) || [];

  let truncatedText = "";
  let charCount = 0;

  for (let word of mixedWordsArray) {
    charCount += word.length + 1; // +1 for the space
    if (charCount > maxChars) break;
    truncatedText += word + " ";
  }

  truncatedText = truncatedText.trim();
  if (charCount > maxChars) truncatedText += "...";
  return truncatedText;
};

export const date_format = (date) => {
  const language = retrieve_item("language");
  const monthNames = [
    "ஜனவரி",
    "பிப்ரவரி",
    "மார்ச்",
    "ஏப்ரல்",
    "மே",
    "ஜூன்",
    "ஜூலை",
    "ஆகஸ்ட்",
    "செப்டம்பர்",
    "அக்டோபர்",
    "நவம்பர்",
    "டிசம்பர்",
  ];
  const dayNames = [
    "ஞாயிறு",
    "திங்கள்",
    "செவ்வாய்",
    "புதன்",
    "வியாழன்",
    "வெள்ளி",
    "சனி",
  ];
  if (typeof date === "number") {
    return moment(date).format("MMM DD, YYYY");
  }

  // Check if the input is a string (ISO 8601 date)
  if (typeof date === "string") {
    if (date.includes("Z")) {
      const get_date = new Date(date);
      const day = get_date.getDate();
      const month = monthNames[get_date.getMonth()];
      const year = get_date.getFullYear();
      const dayName = dayNames[get_date.getDay()];
      if (language?.name === "tamil") {
        return `${day} ${month}, ${year}`;
      } else {
        return moment(date).format("MMM DD, YYYY");
      }
    } else {
      const get_date = new Date(Number(date));
      const day = get_date.getDate();
      const month = monthNames[get_date.getMonth()];
      const year = get_date.getFullYear();
      const dayName = dayNames[get_date.getDay()];
      if (language?.name === "tamil") {
        return `${day} ${month}, ${year}`;
      } else {
        return moment(new Date(Number(date))).format("MMM DD, YYYY");
      }
    }
  }
};

export const split_featured_content = (text, from = 0) => {
  let to = 0;
  const get_language = retrieve_item("language");

  if (get_language.name === "tamil") {
    to = 10;
  } else {
    to = 20;
  }

  const parser = new DOMParser();
  const urlRegex = /https?:\/\/[^\s]+/g;
  const doc = parser.parseFromString(text, "text/html");
  let textContent = doc.body.textContent || "";
  textContent = textContent.replace(urlRegex, "").trim();
  const mixedWordsArray = textContent.match(
    /[\u0B80-\u0BFF\w.]+|\d+(?:\.\d+)?/g
  );
  const dots = mixedWordsArray?.length > to - from ? "..." : "";
  const result = mixedWordsArray
    ? mixedWordsArray.slice(from, to).join(" ")
    : "";
  return `${result}${dots}`;
};

export function get_uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const redirect_to_social_media = (socialMedia) => {
  switch (socialMedia) {
    case "Instagram":
      window.open("https://www.instagram.com/youturn.media/", "_blank");
      break;
    case "Facebook":
      window.open("https://www.facebook.com/youturn.in/", "_blank");
      break;
    case "Twitter":
      window.open("https://twitter.com/youturn_in", "_blank");
      break;
    case "YouTube":
      window.open(
        "https://www.youtube.com/channel/UCCLsLUlxvfdnRwG8_Uh40Ew",
        "_blank"
      );
      break;
    case "LinkedIn":
      window.open("https://in.linkedin.com/in/youturn32017", "_blank");
      break;
    case "Telegram":
      window.open("https://t.me/youturn_in", "_blank");
      break;
    case "Share chat":
      window.open(
        "https://sharechat.com/profile/youturn_in?referer=tagProfileSearchPage",
        "_blank"
      );
      break;
    case "Threads":
      window.open("https://www.threads.net/", "_blank");
      break;
    case "Whatsapp":
      window.open(
        "https://api.whatsapp.com/send?phone=916369748351&text=Hi%20You%20Turn!",
        "_blank"
      );
      break;

    default:
  }
};

export const generate_share_invite_friends = (url) => (
  <div style={{ display: "flex", gap: "10px" }}>
    <a
      href={`whatsapp://send?text=${encodeURIComponent(url)}`}
      data-action="share/whatsapp/share"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={WHATSAPP_FILED_LOGO}
        alt="WhatsApp"
        style={{ height: "25px" }}
      />
    </a>

    <a
      href={`https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(
        url
      )}`}
      target="_blank"
    >
      <img src={FACEBOOK_FILED_LOGO} alt="launch" style={{ height: "25px" }} />
    </a>

    <a
      href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(url)}`}
      target="_blank"
    >
      <img src={TWITTER_FILED_LOGO} alt="launch" style={{ height: "20px" }} />
    </a>
    <a href={`mailto:?body=${encodeURIComponent(url)}`} target="_blank">
      <img src={EMAIL_LOGO} alt="launch" style={{ height: "25px" }} />
    </a>
  </div>
);
export const amountFormatWithSymbol = (value) => {
  if (typeof value === "number" || typeof value === "string")
    return (
      <text>{`${value
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })
        .replace(/\.\d*0$/, "")}`}</text>
    );
  else return <text>{"0"}</text>;
};

export const countFormat = (value) => {
  if (typeof value === "number") return `${value?.toLocaleString("en-IN")}`;
  else return "0";
};

export const get_status_tag = (status) => {
  let color, text, backgroundColor, width, fontSize, borderRadius, fontWeight;
  switch (status) {
    case "published":
      color = "white";
      text = "Published";
      backgroundColor = "#6FBF00";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";

      break;
    case "unpublished":
      color = "white";
      text = "Unpublished";
      backgroundColor = "#00BF93";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";

      break;
    case "pending":
      color = "white";
      text = "To-Do";
      backgroundColor = "#43BF00";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";
      break;
    case "in_progress":
      color = "white";
      text = "In progress";
      backgroundColor = "#005ABF";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";

      break;
    case "under_review":
      color = "white";
      text = "Under Review";
      backgroundColor = "#BF5C00";
      width = "120px";
      fontSize = "16px";
      borderRadius = "4px";
      fontWeight = "bold";

      break;
    case "rejected":
      color = "white";
      text = "Rejected";
      backgroundColor = "#BF0000";
      width = "120px";
      fontSize = "16px";
      fontWeight = "bold";
      break;
    default:
      color = "white";
      text = "Unknown";
      backgroundColor = "#f5f5f5";
      width = "120px";
      fontSize = "16px";
  }

  return (
    <Tag
      style={{
        backgroundColor,
        color,
        width,
        display: "flex",
        justifyContent: "center",
        borderRadius,
        fontWeight,
      }}
    >
      {text}
    </Tag>
  );
};

export const get_status = (status) => {
  let color, text, backgroundColor, width, fontSize, borderRadius, fontWeight;

  if (status === "pending") {
    color = "white";
    backgroundColor = "#43BF00";
    width = "120px";
    fontSize = "16px";
    borderRadius = "4px";
    fontWeight = "bold";
    text = "Pending";
  } else if (status === "published") {
    color = "white";
    backgroundColor = "#6FBF00";
    width = "120px";
    fontSize = "16px";
    borderRadius = "4px";
    fontWeight = "bold";
    text = "Published";
  } else if (status === "approved_by_editor") {
    color = "white";
    backgroundColor = "#6FBF00";
    width = "120px";
    fontSize = "16px";
    borderRadius = "4px";
    fontWeight = "bold";
    text = "Approved by Editor";
  } else if (status === "rejected_by_editor") {
    color = "white";
    backgroundColor = "#BF0000";
    width = "120px";
    fontSize = "16px";
    fontWeight = "bold";

    text = "Rejected by Editor";
  } else if (status === "draft") {
    color = "white";
    text = "Unpublished";
    width = "120px";
    fontSize = "16px";
    borderRadius = "4px";
    fontWeight = "bold";

    text = "Draft";
  } else if (status === "approved_by_admin") {
    color = "white";
    backgroundColor = "#6FBF00";
    width = "120px";
    fontSize = "16px";
    borderRadius = "4px";
    fontWeight = "bold";
    text = "Approved by admin";
  } else if (status === "rejected_by_admin") {
    color = "black";
    backgroundColor = "#BF0000";
    width = "120px";
    fontSize = "16px";
    fontWeight = "bold";

    text = "Rejected by admin";
  } else if (status === "unpublished") {
    color = "white";
    text = "Unpublished";
    width = "120px";
    borderRadius = "4px";
    fontWeight = "bold";
    backgroundColor = "#f54545";
  } else {
    text = null;
  }
  return (
    <Tag
      style={{
        backgroundColor,
        color,
        width,
        display: "flex",
        justifyContent: "center",
        borderRadius,
        fontWeight,
      }}
    >
      {text}
    </Tag>
  );
};

export const initiate_request_razorpay_payment = async (
  order_id,
  set_payment_response,
  mobile_number
) => {
  let contact = retrieve_item("mobile");
  var options = {
    key: process.env.REACT_APP_RAZORPAY_PAYMENY_KEY || "",
    currency: "INR",
    description: "Test Transaction",
    order_id: order_id,
    mobile_number: mobile_number,
    handler: function (response) {
      set_payment_response(response);
    },
    prefill: {
      contact: mobile_number || contact,
    },
    theme: {
      color: "#F37254",
    },
  };
  if (order_id !== store_order_id) {
    store_order_id = order_id;
    let rzp1 = new Razorpay(options);
    rzp1.open();
  }
};
function isMobileDevice() {
  return /Android|iPhone/i.test(navigator.userAgent);
}
export function get_share_content(
  title,
  share_url,
  thumbnail_img,
  copy_to_clipboard,
  show_copy_link_popup
) {
  const whatsapp_url = isMobileDevice()
    ? `whatsapp://send?text=${encodeURIComponent(`${title}\n${share_url}`)}`
    : `https://web.whatsapp.com/send?text=${encodeURIComponent(`${title}\n${share_url}`)}`;

    
  return (
    <div className="share_container">
      <a
        href={whatsapp_url}
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={WHATSAPP_FILED_LOGO}
          alt="WhatsApp"
          className="article_share_icon"
        />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          share_url
        )}&quote=${encodeURIComponent(title)}`}
        target="_blank"
      >
        <img
          src={FACEBOOK_FILED_LOGO}
          alt="Facebook"
          className="article_share_icon"
        />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          title
        )}&url=${encodeURIComponent(share_url)}&media=${encodeURIComponent(
          thumbnail_img
        )}`}
        target="_blank"
      >
        <img
          src={TWITTER_FILED_LOGO}
          alt="Twitter"
          className="article_share_icons"
        />
      </a>
      <a
        href={`mailto:?subject=${encodeURIComponent(
          title
        )}&body=${encodeURIComponent(share_url)}`}
        target="_blank"
      >
        <img src={EMAIL_LOGO} alt="Email" className="article_share_icon" />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          share_url
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={LINKEDIN_FILED_LOGO}
          alt="LinkedIn"
          className="article_share_icons"
        />
      </a>
      <a
          href={`https://t.me/share/url?url=${encodeURIComponent(
            share_url
          )}&text=${encodeURIComponent(title)}`}
        target="_blank"
         rel="noopener noreferrer"
      >
        <img
          src={TELEGRAM_FILED_LOGO}
          alt="Telegram"
          className="article_share_icon"
        />
      </a>
      <Tooltip
        title={"Link copied"}
        open={show_copy_link_popup}
        destroyTooltipOnHide={true}
        mouseLeaveDelay={3}
      >
        <img
          src={LINK_ICON}
          alt="Copy link"
          className="copy_to_clipboard_icon"
          onClick={() => {
            copy_to_clipboard();
          }}
        />
      </Tooltip>
    </div>
  );
}

export const ensure_https = (image_url) => {
  if (!image_url) return null;
  return image_url.replace(/^http:/, "https:");
};
export const split_title_for_bread_crumb = (text, to) => {
  const get_language = retrieve_item("language");

  if (get_language.name === "tamil") {
    to = 50;
  }
  const words = text ? text.split(" ") : [];
  const truncated_words = words.slice(0, 3).join(" ");
  const dots = words.length > 3 ? "..." : "";

  return `${truncated_words}${dots}`;
};

export const get_language = () => {
  let get_session_id = retrieve_item("session_id");
  let url = window.location.href;
  if (url.includes(process.env.REACT_APP_EN_WEB_URL)) {
    changeLanguage("en");
    store_item("language", {
      name: "english",
      id: language?.english_id,
      key: "en",
    });
    if (get_session_id) {
      store_item("session_id", get_session_id);
    }
    return "ta";
  } else if (url.includes(process.env.REACT_APP_WEB_URL)) {
    changeLanguage("ta");
    store_item("language", {
      name: "tamil",
      id: language?.tamil_id,
      key: "ta",
    });
    if (get_session_id) {
      store_item("session_id", get_session_id);
    }
    return "en";
  } else {
    changeLanguage("ta");
    store_item("language", {
      name: "tamil",
      id: language?.tamil_id,
      key: "ta",
    });
    if (get_session_id) {
      store_item("session_id", get_session_id);
    }
    return "en";
  }
};

export const reading_time = (content) => {
  return useReadingTime(content);
};

  export const get_reading_time = (reading_time, t) => {
    if (typeof t !== 'function') {
      console.error("Translation function `t` is not correctly passed.");
      return reading_time; // Fallback to just the reading time if t is not a function
    }
    return reading_time + " " + t(reading_time === 1 ? "min" : "mins");
  };
  
  
