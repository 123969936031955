// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2560px) {
  .login_page_logo_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
  }
  .user_profile_container {
    width: 80px;
    height: 80px;
  }
  .sign_up_input{
    width: 300px !important;
  }
  
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_2560_layout/screen_2560_login.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,UAAU;EACZ;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,uBAAuB;EACzB;;AAEF","sourcesContent":["@media only screen and (min-width: 2560px) {\r\n  .login_page_logo_container {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: flex-start;\r\n    width: 90%;\r\n  }\r\n  .user_profile_container {\r\n    width: 80px;\r\n    height: 80px;\r\n  }\r\n  .sign_up_input{\r\n    width: 300px !important;\r\n  }\r\n  \r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
