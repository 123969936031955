// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .related_recent_fact_check_title_container {
    height: 50px;
  }
  .recent_fact_check_title_text {
    font-size: 15px !important;
    line-height: 21px !important;
  }
  .single_fact_check_claim_container {
    position: sticky !important;
    top: 180px;
    bottom: 10px;
  }

  .recent_fact_check_title_text_ta {
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .reply_comment_text {
    font-size: 15px;
  }
  .donation_first_row_content_en {
    font-size: 20px;
    line-height: 25px !important;
  }
  .whatsapp_channel_text {
    font-size: 20px;
    line-height: 20px;
  }
  .claim_wrapper_mob_screen {
    display: none;
  }
  .single_fact_check_whats_app_mobile_container{
    display: none !important;
  }
  .single_fact_check_spread_news_container img{
    width: 100% !important;
    height: auto !important;
  }
  .single_fact_check_spread_news_container >p> img{
    width: 100% !important;
    height: auto !important;
      }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1440_1999/screen_1440_1999_single_fact_check.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;EACd;EACA;IACE,0BAA0B;IAC1B,4BAA4B;EAC9B;EACA;IACE,2BAA2B;IAC3B,UAAU;IACV,YAAY;EACd;;EAEA;IACE,0BAA0B;IAC1B,4BAA4B;EAC9B;;EAEA;IACE,eAAe;EACjB;EACA;IACE,eAAe;IACf,4BAA4B;EAC9B;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,aAAa;EACf;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,sBAAsB;IACtB,uBAAuB;EACzB;EACA;IACE,sBAAsB;IACtB,uBAAuB;MACrB;AACN","sourcesContent":["@media only screen and (min-width: 1440px) and (max-width: 1999px) {\r\n  .related_recent_fact_check_title_container {\r\n    height: 50px;\r\n  }\r\n  .recent_fact_check_title_text {\r\n    font-size: 15px !important;\r\n    line-height: 21px !important;\r\n  }\r\n  .single_fact_check_claim_container {\r\n    position: sticky !important;\r\n    top: 180px;\r\n    bottom: 10px;\r\n  }\r\n\r\n  .recent_fact_check_title_text_ta {\r\n    font-size: 14px !important;\r\n    line-height: 21px !important;\r\n  }\r\n\r\n  .reply_comment_text {\r\n    font-size: 15px;\r\n  }\r\n  .donation_first_row_content_en {\r\n    font-size: 20px;\r\n    line-height: 25px !important;\r\n  }\r\n  .whatsapp_channel_text {\r\n    font-size: 20px;\r\n    line-height: 20px;\r\n  }\r\n  .claim_wrapper_mob_screen {\r\n    display: none;\r\n  }\r\n  .single_fact_check_whats_app_mobile_container{\r\n    display: none !important;\r\n  }\r\n  .single_fact_check_spread_news_container img{\r\n    width: 100% !important;\r\n    height: auto !important;\r\n  }\r\n  .single_fact_check_spread_news_container >p> img{\r\n    width: 100% !important;\r\n    height: auto !important;\r\n      }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
