// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1440px) and (max-width: 2559px) {
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1440_1999/screen_1440_2559_comments.css"],"names":[],"mappings":"AAAA;AACA","sourcesContent":["@media only screen and (min-width: 1440px) and (max-width: 2559px) {\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
