// RedirectHandler.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RedirectHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get("p"); // Extract the 'p' parameter
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (param) {
        // Check if 'p' parameter exists
        try {
          const response = await axios.get(
            process.env.REACT_APP_SITE_REDIRECT_API,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                referer: process.env.REACT_APP_WEB_URL,
                p: param,
              },
            }
          );

          // Redirect to the URL from API response
          const redirectUrl = response?.data?.result?.url;
          if (redirectUrl) {
            window.location.replace(redirectUrl);
          } else {
            throw new Error("Redirect URL not found");
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      } else {
        console.log("No 'p' parameter found in the URL.");
        setLoading(false);
      }
    };

    fetchData();
  }, [param]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error state
  }

  return null; // No need to render anything as we're redirecting
};

export default RedirectHandler;
