// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 0px) and (max-width: 767px) {
  .support_us_section_wrapper {
    display: none;
  }
  .support_us_card {
    width: 100%;
  }
  .support_us_section_container {
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_767/screen_767_support_us.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;EACA;IACE,WAAW;EACb;EACA;IACE,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV;AACF","sourcesContent":["@media only screen and (min-width: 0px) and (max-width: 767px) {\r\n  .support_us_section_wrapper {\r\n    display: none;\r\n  }\r\n  .support_us_card {\r\n    width: 100%;\r\n  }\r\n  .support_us_section_container {\r\n    padding-top: 2px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 5px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
