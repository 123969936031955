// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .fact_check_items_container {
    gap: 20px;
  }
  .fact_check_image_one {
    margin-left: 0;
  }
  .fact_check_item {
    gap: 8px;
  }
  .fact_check_description_one {
    width: 270px;
  }
  .funding_container_right {
    width: 55%;
    background-color: #140f2d;
    margin: 0px 0px 20px 30px;
    padding: 30px 60px;
    border-radius: 12px;
  }
  .funding_head {
    margin-top: 0;
  }
  .award_list_image {
    width: 200px;
  }
  .team_members_profile_card {
    width: 100%;
    margin-bottom: 30px;
    gap: 60px;
    padding: 15px;
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
  .team_members_details {
    background-color: transparent;
    border-color: #ffb037;
    border-radius: 10px;
  }
  .about_us_join_use_card_container {
    display: none;
  }
  .aboutus_banner_content_ta {
    color: #ffffff;
    font-family: "Lato";
    font-size: 15px;
    text-align: justify;
  }
  .aboutus_banner_container {
    padding: 5px 50px 30px 50px;
  }
  .ifcn_certificates_notes{
    display: flex;
    align-items: end;
    gap: 20px;
  }
  .notes_text_size{
    font-size: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1024_1439/screen_1024_1439_aboutus.css"],"names":[],"mappings":"AAAA;EACE;IACE,SAAS;EACX;EACA;IACE,cAAc;EAChB;EACA;IACE,QAAQ;EACV;EACA;IACE,YAAY;EACd;EACA;IACE,UAAU;IACV,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,aAAa;EACf;EACA;IACE,YAAY;EACd;EACA;IACE,WAAW;IACX,mBAAmB;IACnB,SAAS;IACT,aAAa;IACb,wCAAwC;EAC1C;EACA;IACE,6BAA6B;IAC7B,qBAAqB;IACrB,mBAAmB;EACrB;EACA;IACE,aAAa;EACf;EACA;IACE,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,aAAa;IACb,gBAAgB;IAChB,SAAS;EACX;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["@media only screen and (min-width: 1024px) and (max-width: 1439px) {\r\n  .fact_check_items_container {\r\n    gap: 20px;\r\n  }\r\n  .fact_check_image_one {\r\n    margin-left: 0;\r\n  }\r\n  .fact_check_item {\r\n    gap: 8px;\r\n  }\r\n  .fact_check_description_one {\r\n    width: 270px;\r\n  }\r\n  .funding_container_right {\r\n    width: 55%;\r\n    background-color: #140f2d;\r\n    margin: 0px 0px 20px 30px;\r\n    padding: 30px 60px;\r\n    border-radius: 12px;\r\n  }\r\n  .funding_head {\r\n    margin-top: 0;\r\n  }\r\n  .award_list_image {\r\n    width: 200px;\r\n  }\r\n  .team_members_profile_card {\r\n    width: 100%;\r\n    margin-bottom: 30px;\r\n    gap: 60px;\r\n    padding: 15px;\r\n    grid-template-columns: 33.3% 33.3% 33.3%;\r\n  }\r\n  .team_members_details {\r\n    background-color: transparent;\r\n    border-color: #ffb037;\r\n    border-radius: 10px;\r\n  }\r\n  .about_us_join_use_card_container {\r\n    display: none;\r\n  }\r\n  .aboutus_banner_content_ta {\r\n    color: #ffffff;\r\n    font-family: \"Lato\";\r\n    font-size: 15px;\r\n    text-align: justify;\r\n  }\r\n  .aboutus_banner_container {\r\n    padding: 5px 50px 30px 50px;\r\n  }\r\n  .ifcn_certificates_notes{\r\n    display: flex;\r\n    align-items: end;\r\n    gap: 20px;\r\n  }\r\n  .notes_text_size{\r\n    font-size: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
