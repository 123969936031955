// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor_profile_image {
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  min-width: 140px !important;
}
.contributions_card_wrapper {
  background-color: #ffffff;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.writer_profile_wrapper {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.author_contribution_list_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  padding: 2%;
}
.social_media_urls {
  width: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/layout/editor_profile.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":[".editor_profile_image {\r\n  height: 100%;\r\n  border-radius: 50%;\r\n  object-fit: cover;\r\n  min-width: 140px !important;\r\n}\r\n.contributions_card_wrapper {\r\n  background-color: #ffffff;\r\n  height: 200px;\r\n  border-radius: 10px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  gap: 15px;\r\n  cursor: pointer;\r\n}\r\n.writer_profile_wrapper {\r\n  display: flex;\r\n  justify-content: space-around;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.author_contribution_list_container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 20px;\r\n  padding-top: 30px;\r\n  padding: 2%;\r\n}\r\n.social_media_urls {\r\n  width: 40px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
