// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .bookmark_body_container {
    flex-direction: row;
    justify-content: space-around;
    height: 70%;
    align-items: center;
  }

  .about-me > p {
    font-size: 13px;
    min-height: 100px;
  }
  .profile-card {
    width: 100%;
    min-height: 510px;
  }

  .book_mark_container {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    gap: 11px;
    padding: 2%;
    width: 100%;
  }
  .bookmark_title_container {
    padding-left: 30px;
    padding-right: 20px;
  }

 
  .donation-item {
    padding: 10px 0px;
  }
  .user_dashboard_main_container {
    padding: 2% 0 2% 0;
  }
 
  .donations-header h6 {
    font-size: 15px;
  }
  .donations-header > #view-all {
    font-size: 11px;
  }
  .bookmark_header > h6{
    font-size: 15px;

  }
  .no_transaction{
    font-size:13px;
    margin-bottom: 15px;
  }
 
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1024_1439/screen_1024_1439_user_profile.css"],"names":[],"mappings":"AAAA;EACE;IACE,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,wCAAwC;IACxC,SAAS;IACT,WAAW;IACX,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;;EAGA;IACE,iBAAiB;EACnB;EACA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;;EAEjB;EACA;IACE,cAAc;IACd,mBAAmB;EACrB;;AAEF","sourcesContent":["@media only screen and (min-width: 1024px) and (max-width: 1439px) {\r\n  .bookmark_body_container {\r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n    height: 70%;\r\n    align-items: center;\r\n  }\r\n\r\n  .about-me > p {\r\n    font-size: 13px;\r\n    min-height: 100px;\r\n  }\r\n  .profile-card {\r\n    width: 100%;\r\n    min-height: 510px;\r\n  }\r\n\r\n  .book_mark_container {\r\n    display: grid;\r\n    grid-template-columns: 33.3% 33.3% 33.3%;\r\n    gap: 11px;\r\n    padding: 2%;\r\n    width: 100%;\r\n  }\r\n  .bookmark_title_container {\r\n    padding-left: 30px;\r\n    padding-right: 20px;\r\n  }\r\n\r\n \r\n  .donation-item {\r\n    padding: 10px 0px;\r\n  }\r\n  .user_dashboard_main_container {\r\n    padding: 2% 0 2% 0;\r\n  }\r\n \r\n  .donations-header h6 {\r\n    font-size: 15px;\r\n  }\r\n  .donations-header > #view-all {\r\n    font-size: 11px;\r\n  }\r\n  .bookmark_header > h6{\r\n    font-size: 15px;\r\n\r\n  }\r\n  .no_transaction{\r\n    font-size:13px;\r\n    margin-bottom: 15px;\r\n  }\r\n \r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
