// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .featured_article_image_component {
    width: 60% !important;
  position: relative;

  }
  .featured_article_thumbnail_overlay_content {
    height: 145px;
    width: 62% !important;
  }
  .featured_article_image_component_title {
    font-size: 20px;
  }

  .featured_article_view_count {
    font-size: 15px;
  }
  .featured_article_thumbnail_overlay_name_and_date {
    font-size: 15px;
  }
  .featured_article_button {
    font-size: 14px;
  }
  .featured_story_article {
    font-size: 30px;
  }
  .featured_story_article_title {
    font-size: 22px;
  }
  .featured_article_sub_title {
    font-size: 18px;
  }
  .featured_article_title {
    width: 90%;
  }
  .recommended_story_mobile_main_container {
    display: none;
  }
  .featured_story_continue_reading_text {
    font-size: 16px;
  }
  .featured_article_image_component_title_ta {
    font-size: 18px;
  }
  .featured_story_article_title_ta {
    font-size: 22px !important;
  }
  .featured_article_sub_title_ta {
    font-size: 18px;
  }
  .recommended_story_desktop_title_container > h2 {
    font-weight: 600;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1440_1999/screen_1440_1999_article.css"],"names":[],"mappings":"AAAA;EACE;IACE,qBAAqB;EACvB,kBAAkB;;EAElB;EACA;IACE,aAAa;IACb,qBAAqB;EACvB;EACA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,UAAU;EACZ;EACA;IACE,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":["@media only screen and (min-width: 1440px) and (max-width: 1999px) {\r\n  .featured_article_image_component {\r\n    width: 60% !important;\r\n  position: relative;\r\n\r\n  }\r\n  .featured_article_thumbnail_overlay_content {\r\n    height: 145px;\r\n    width: 62% !important;\r\n  }\r\n  .featured_article_image_component_title {\r\n    font-size: 20px;\r\n  }\r\n\r\n  .featured_article_view_count {\r\n    font-size: 15px;\r\n  }\r\n  .featured_article_thumbnail_overlay_name_and_date {\r\n    font-size: 15px;\r\n  }\r\n  .featured_article_button {\r\n    font-size: 14px;\r\n  }\r\n  .featured_story_article {\r\n    font-size: 30px;\r\n  }\r\n  .featured_story_article_title {\r\n    font-size: 22px;\r\n  }\r\n  .featured_article_sub_title {\r\n    font-size: 18px;\r\n  }\r\n  .featured_article_title {\r\n    width: 90%;\r\n  }\r\n  .recommended_story_mobile_main_container {\r\n    display: none;\r\n  }\r\n  .featured_story_continue_reading_text {\r\n    font-size: 16px;\r\n  }\r\n  .featured_article_image_component_title_ta {\r\n    font-size: 18px;\r\n  }\r\n  .featured_story_article_title_ta {\r\n    font-size: 22px !important;\r\n  }\r\n  .featured_article_sub_title_ta {\r\n    font-size: 18px;\r\n  }\r\n  .recommended_story_desktop_title_container > h2 {\r\n    font-weight: 600;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
