import moment from "moment";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

export const ProjectName = "YouTurn";

export const language = {
  tamil_id: "839bcc29-853f-4c4c-8700-98fd88558952",
  english_id: "ea83e859-ce26-4086-9e85-ec40f5dbe4f0",
};

export const box_shadow = "5px 5px 15px 0px rgba(0, 101, 204, 0.15)";

export const SITE_URL =
  process.env.REACT_APP_WEB_URL || "https://youturn.in/";