// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .contact_us_donation_wrapper {
  }
  .contact_us_section_wrapper {
    padding: 5% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1440_1999/screen_1440_2559_contact_us.css"],"names":[],"mappings":"AAAA;EACE;EACA;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["@media only screen and (min-width: 1440px) and (max-width: 2559px) {\r\n  .contact_us_donation_wrapper {\r\n  }\r\n  .contact_us_section_wrapper {\r\n    padding: 5% !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
