// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2560px) {
  .mobile_view_for_first_section {
    display: none;
  }
  .user_layout_mobile_view_search {
    display: none;
  }
  .fact-check-list-news-story-card-skeleton {
    width: 1400px !important;
    height: 600px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_2560_layout/screen_2560_search_results.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;EACA;IACE,wBAAwB;IACxB,wBAAwB;EAC1B;AACF","sourcesContent":["@media only screen and (min-width: 2560px) {\r\n  .mobile_view_for_first_section {\r\n    display: none;\r\n  }\r\n  .user_layout_mobile_view_search {\r\n    display: none;\r\n  }\r\n  .fact-check-list-news-story-card-skeleton {\r\n    width: 1400px !important;\r\n    height: 600px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
