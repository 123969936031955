// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondary_donation_container {
  height: 100%;
  width: 100%;
  padding: 25px;
  border-radius: 15px;
  background-color: var(--background-theme-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.secondary_donation_fight_against_container {
  width: 70%;
  height: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #f49d36;
}
.secondary_donation_content_container {
  width: 100%;
  height: 45%;
}
.secondary_donation_amount_container {
  width: 100%;
  height: 17%;
}
.secondary_donation_button_container {
  width: 100%;
  height: 15%;
}
.secondary_donation_amount_wrapper {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.donation_secondary_banner {
  width: 36%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/layout/user_donation_common.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,+CAA+C;EAC/C,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,UAAU;EACV,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,WAAW;EACX,WAAW;AACb;AACA;EACE,WAAW;EACX,WAAW;AACb;AACA;EACE,WAAW;EACX,WAAW;AACb;AACA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,UAAU;AACZ","sourcesContent":[".secondary_donation_container {\r\n  height: 100%;\r\n  width: 100%;\r\n  padding: 25px;\r\n  border-radius: 15px;\r\n  background-color: var(--background-theme-color);\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n}\r\n.secondary_donation_fight_against_container {\r\n  width: 70%;\r\n  height: 25%;\r\n  display: flex;\r\n  flex-direction: row;\r\n  align-items: center;\r\n  color: #f49d36;\r\n}\r\n.secondary_donation_content_container {\r\n  width: 100%;\r\n  height: 45%;\r\n}\r\n.secondary_donation_amount_container {\r\n  width: 100%;\r\n  height: 17%;\r\n}\r\n.secondary_donation_button_container {\r\n  width: 100%;\r\n  height: 15%;\r\n}\r\n.secondary_donation_amount_wrapper {\r\n  width: 80%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n}\r\n.donation_secondary_banner {\r\n  width: 36%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
