// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .writer_description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .contributions_card_wrapper {
    width: 100%;
  }
  .contributions_card_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
  .writer_profile_wrapper {
    gap: 7px;
    padding: 2% !important;
  }
  .writer_profile_details_container {
    width: 90%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
    gap: 20px;
    border-radius: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_768_1023/screen_768_1023_writer_profile.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;EACA;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,SAAS;EACX;EACA;IACE,QAAQ;IACR,sBAAsB;EACxB;EACA;IACE,UAAU;IACV,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,SAAS;IACT,mBAAmB;EACrB;AACF","sourcesContent":["@media only screen and (min-width: 768px) and (max-width: 1023px) {\r\n  .writer_description {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n  }\r\n  .contributions_card_wrapper {\r\n    width: 100%;\r\n  }\r\n  .contributions_card_container {\r\n    width: 90%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    gap: 20px;\r\n  }\r\n  .writer_profile_wrapper {\r\n    gap: 7px;\r\n    padding: 2% !important;\r\n  }\r\n  .writer_profile_details_container {\r\n    width: 90%;\r\n    background-color: #ffffff;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding: 2%;\r\n    gap: 20px;\r\n    border-radius: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
