// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2000px) and (max-width: 2559px) {
  .featured_story_article_title_ta {
    font-size: 26px;
  }
  .featured_article_title {
    width: 90%;
  }
  .featured_article_image_component {
    width: 60% !important;
  position: relative;

  }
  .featured_story_image_skeleton {
    height: 600px !important;
  }
  .featured_article_thumbnail_overlay_content {
    height: 160px;
    width: 65% !important;
  }
  .featured_story_article {
    font-size: 35px;
  }
  .featured_article_button {
    font-size: 15px;
  }
  .featured_story_article_title {
    font-size: 26px;
  }
  .featured_article_sub_title {
    font-size: 23px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_2000_2559/screen_2000_2559_articles.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;EACjB;EACA;IACE,UAAU;EACZ;EACA;IACE,qBAAqB;EACvB,kBAAkB;;EAElB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,aAAa;IACb,qBAAqB;EACvB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["@media only screen and (min-width: 2000px) and (max-width: 2559px) {\r\n  .featured_story_article_title_ta {\r\n    font-size: 26px;\r\n  }\r\n  .featured_article_title {\r\n    width: 90%;\r\n  }\r\n  .featured_article_image_component {\r\n    width: 60% !important;\r\n  position: relative;\r\n\r\n  }\r\n  .featured_story_image_skeleton {\r\n    height: 600px !important;\r\n  }\r\n  .featured_article_thumbnail_overlay_content {\r\n    height: 160px;\r\n    width: 65% !important;\r\n  }\r\n  .featured_story_article {\r\n    font-size: 35px;\r\n  }\r\n  .featured_article_button {\r\n    font-size: 15px;\r\n  }\r\n  .featured_story_article_title {\r\n    font-size: 26px;\r\n  }\r\n  .featured_article_sub_title {\r\n    font-size: 23px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
