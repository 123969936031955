// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .user_dashboard_second_container,
  .user_dashboard_third_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1024_1439/screen_1024_1439_user_donation.css"],"names":[],"mappings":"AAAA;EACE;;IAEE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,YAAY;EACd;AACF","sourcesContent":["@media only screen and (min-width: 1024px) and (max-width: 1439px) {\r\n  .user_dashboard_second_container,\r\n  .user_dashboard_third_container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
